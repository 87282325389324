// actions/postsActions.js
import servicesData from '../../data/services.json';

export const FETCH_POSTS_BEGIN = 'FETCH_POSTS_BEGIN';
export const FETCH_POSTS_SUCCESS = 'FETCH_POSTS_SUCCESS';
export const FETCH_POSTS_FAILURE = 'FETCH_POSTS_FAILURE';

export const fetchPostsBegin = () => ({
  type: FETCH_POSTS_BEGIN
});

export const fetchPostsSuccess = posts => ({
  type: FETCH_POSTS_SUCCESS,
  payload: { posts }
});

export const fetchPostsFailure = error => ({
  type: FETCH_POSTS_FAILURE,
  payload: { error }
});

export const fetchPosts = () => {
  return dispatch => {
    dispatch(fetchPostsBegin());
    // Simulate an asynchronous call
    setTimeout(() => {
      dispatch(fetchPostsSuccess(servicesData));
    }, 500);
  };
};
