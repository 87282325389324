import React from 'react';
import MainLayout from '../layouts/MainLayout';
import WhyUs from '../components/home/whyUs';
import AboutComp from '../components/home/aboutComp';
import Services from '../components/home/services';
import Product from '../components/home/product';

const Home = () => {
 
  return (
    <MainLayout>
     <WhyUs />
    <AboutComp />
    <Services />
    <Product />
    </MainLayout>
  );
};

export default Home;
