import React from 'react';

const TermsOfUse = () => {
  return (
    <div className="container mx-auto p-4 md:p-8">
      {/* <h1 className="text-3xl md:text-4xl font-bold text-center mb-8">Terms of Use</h1> */}

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">General Information</h2>
        <p className="text-gray-700 mb-4">
          These terms and conditions outline the rules and regulations for the use of Ceylon Sprint Pvt Ltd's Website. By accessing this website, we assume you accept these terms and conditions in full. Do not continue to use Ceylon Sprint Pvt Ltd's website if you do not accept all of the terms and conditions stated on this page.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">Use of the Website</h2>
        <p className="text-gray-700 mb-4">
          The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and any or all Agreements: “Client”, “You” and “Your” refers to you, the person accessing this website and accepting the Company’s terms and conditions. “The Company”, “Ourselves”, “We”, “Our” and “Us”, refers to our Company.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">Modification of Terms</h2>
        <p className="text-gray-700 mb-4">
          Ceylon Sprint Pvt Ltd reserves the right to modify these terms at any time. It is your responsibility to review these terms regularly. Your continued use of the website following any changes indicates your acceptance of the new terms.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">User Responsibilities</h2>
        <p className="text-gray-700 mb-4">
          As a user, you agree not to misuse the services provided by Ceylon Sprint Pvt Ltd or help anyone else do so. You are responsible for all activities under your account. You must comply with all applicable laws and respect the rights of others.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">Intellectual Property</h2>
        <p className="text-gray-700 mb-4">
          All trademarks, service marks, trade names, logos, and graphics on this site are the property of Ceylon Sprint Pvt Ltd or their respective owners. Unauthorized use of these may give rise to a claim for damages and/or be a criminal offence.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">Limitation of Liability</h2>
        <p className="text-gray-700 mb-4">
          Ceylon Sprint Pvt Ltd is not liable for any damages that occur from the use or inability to use the materials on this website, even if Ceylon Sprint Pvt Ltd has been notified orally or in writing of the possibility of such damage.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold">Governing Law</h2>
        <p className="text-gray-700">
          Your use of this website and any dispute arising out of such use of the website is subject to the laws of the country where Ceylon Sprint Pvt Ltd is registered.
        </p>
      </section>
    </div>
  );
};

export default TermsOfUse;
