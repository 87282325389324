import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto p-4 md:p-8">
      <h1 className="text-3xl md:text-4xl font-bold text-center mb-8">Privacy Policy</h1>
      
      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">Introduction</h2>
        <p className="text-gray-700 mb-4">
          At Ceylon Sprint Pvt Ltd, we are committed to maintaining the trust and confidence of our visitors to our website. In this Privacy Policy, we’ve provided detailed information on when and why we collect your personal information, how we use it, and how we keep it secure.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">Data Collection and Use</h2>
        <p className="text-gray-700 mb-4">
          We collect information to provide better services to our users. This includes basic user information such as your name and email, as well as more detailed information like preferences and interests.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">Cookies and Web Beacons</h2>
        <p className="text-gray-700 mb-4">
          Like any other website, Ceylon Sprint Pvt Ltd uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">Data Security</h2>
        <p className="text-gray-700 mb-4">
          We prioritize the security of your data. We take reasonable measures to protect your personal information from unauthorized access, use, alteration, or destruction.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">Your Data Rights</h2>
        <p className="text-gray-700 mb-4">
          You have the right to access, update, or delete the information we have on you. Any data we have stored is available upon your request.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">Policy Updates</h2>
        <p className="text-gray-700">
          Our Privacy Policy may change from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold">Contact Information</h2>
        <p className="text-gray-700">
          If you have any questions about our Privacy Policy, feel free to contact us.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
