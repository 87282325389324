// Define action types
export const SEND_CONTACT_REQUEST = 'SEND_CONTACT_REQUEST';
export const CONTACT_REQUEST_SUCCESS = 'CONTACT_REQUEST_SUCCESS';
export const CONTACT_REQUEST_FAILURE = 'CONTACT_REQUEST_FAILURE';
const apiURL = process.env.REACT_APP_API_URL;

export const sendContactRequest = () => ({
    type: SEND_CONTACT_REQUEST
});

export const contactRequestSuccess = (data) => ({
    type: CONTACT_REQUEST_SUCCESS,
    payload: data
});

export const contactRequestFailure = (error) => ({
    type: CONTACT_REQUEST_FAILURE,
    payload: error
});
export const submitContactForm = (formData,csrfToken) => {
    return async (dispatch) => {
        dispatch(sendContactRequest());
        try {
            const response = await fetch(`${apiURL}/submit-contact`, {
                method: 'POST',
                withCredentials:true,
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': csrfToken
                },
                credentials: 'include',
                body: JSON.stringify(formData)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            dispatch(contactRequestSuccess(data));
            return data; 
        } catch (error) {
            dispatch(contactRequestFailure(error.toString()));
        }
    };
};
