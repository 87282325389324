import React from 'react';
import Slider from 'react-slick'; // Importing react-slick
import 'slick-carousel/slick/slick.css'; // Importing slick-carousel styles
import 'slick-carousel/slick/slick-theme.css';

const products = [
  { name: 'Invoice Package', description: 'Streamlined invoicing and billing solutions.' },
  { name: 'HR – Payroll Package', description: 'Comprehensive HR and payroll management system.' },
  { name: 'Account Package', description: 'Robust accounting software for all your financial needs.' },
  { name: 'CRM', description: 'Customer Relationship Management to enhance customer engagement.' },
  { name: 'Inventory Management System', description: 'Efficient inventory tracking and management tools.' }
];

const Product = () => {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,  // Adjust the speed as per your preference
      slidesToShow: 3,  // Show one item at a time
      slidesToScroll: 2,  // Scroll one item at a time
      autoplay: true,
      autoplaySpeed: 3000,  // Adjust rotation speed as needed
      // Optional: Add other animation styles or effects here
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    };
  
    return (
      <div className="bg-white p-4 md:p-8">
        <h2 className="text-2xl font-bold text-center mb-6">Our Products</h2>
        <Slider {...settings}>
          {products.map((product, index) => (
            <div key={index} className="p-4">
              <div className="border border-gray-200 rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300">
                <h3 className="text-xl font-semibold mb-3">{product.name}</h3>
                <p className="text-md">{product.description}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    );
  };
  
  export default Product;
