import React,{useState} from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { submitContactForm } from '../../store/actions/contactActions';
import { FaPhone, FaEnvelope, FaBuilding, FaCommentAlt, FaWhatsapp } from 'react-icons/fa'; // Importing icons

const GetIn = () => {
  const dispatch = useDispatch();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const whatsAppNumber = "+94707330432";
  const companyLogoURL = "https://yourwebsite.com/path-to-your-logo.jpg";
  const message = `Hello, I'm interested in learning more about your services. Here's our logo: ${companyLogoURL}`;
  const urlEncodedMessage = encodeURIComponent(message);
  const whatsAppLink = `https://wa.me/${whatsAppNumber}?text=${urlEncodedMessage}`;
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      organization: '',
      message: ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      email: Yup.string().email('Invalid email address').required('Email is required'),
      phone: Yup.string().required('Phone number is required'),
      organization: Yup.string(),
      message: Yup.string().required('Message is required')
    }),
    onSubmit: values => {
      dispatch(submitContactForm(values))
      .then(data => {
          // Here data is the response data from your API
          // console.log("Response Data:", data);
          setIsSubmitted(true);
          // Reset form if needed
          formik.resetForm();
      })
      .catch(error => {
          console.error('Submission Error:', error);
      });
  }
  });

  return (
    <div className="bg-blue-300 py-12">
      <div className="container mx-auto px-4 md:flex items-center justify-between">
        {/* Left Side: Contact Form */}
        <div className="md:w-1/2 bg-gray-300 p-6 rounded shadow-lg">
        {isSubmitted && <div className="text-green-500 mb-4">Thank you for contacting us. We will get back to you soon.</div>}
        <form onSubmit={formik.handleSubmit}>
            <input
              type="text"
              name="name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              placeholder="Your Name"
              className="w-full p-2 border border-gray-300 rounded mb-2"
            />
            {formik.touched.name && formik.errors.name && <div className="text-red-500 text-xs mb-2">{formik.errors.name}</div>}

            <input
              type="text"
              name="phone"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
              placeholder="Your Phone Number"
              className="w-full p-2 border border-gray-300 rounded mb-2"
            />
            {formik.touched.phone && formik.errors.phone && <div className="text-red-500 text-xs mb-2">{formik.errors.phone}</div>}

            <input
              type="email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              placeholder="Your Email"
              className="w-full p-2 border border-gray-300 rounded mb-2"
            />
            {formik.touched.email && formik.errors.email && <div className="text-red-500 text-xs mb-2">{formik.errors.email}</div>}

            <input
            type="text"
            name="organization"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.organization}
            placeholder="Your Organization"
            className="w-full p-2 border border-gray-300 rounded mb-2"
          />
          {formik.touched.organization && formik.errors.organization && <div className="text-red-500 text-xs mb-2">{formik.errors.organization}</div>}




            <textarea
              name="message"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.message}
              placeholder="Your Message"
              className="w-full p-2 border border-gray-300 rounded mb-2"
              rows="4"
            ></textarea>
            {formik.touched.message && formik.errors.message && <div className="text-red-500 text-xs mb-2">{formik.errors.message}</div>}

            <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors duration-300">
              Submit
            </button>
          </form>
        </div>

        {/* Right Side: Contact Information */}
        <div className="md:w-1/2 p-6 ml-4 mt-4 md:mt-0 flex flex-col items-start">
          <p className="text-lg mb-4">Ready to connect with us? Explore the multiple ways to reach out below, or simply provide your details in the form on the left. We're excited to hear from you and promise a prompt callback. Let's start a conversation that could transform your project!</p>
          <h3 className="text-xl font-bold text-left mb-3">Contact Information</h3>
          <div className="space-y-2">
            <div className="flex items-center">
              <FaPhone className="text-blue-500 mr-2" />
              <p>Hotline: +94 70 733 0 433</p>
            </div>
            <div className="flex items-center">
              <FaEnvelope className="text-blue-500 mr-2" />
              <p>Email: info@ceylonsprint.com</p>
            </div>
            <div className="flex items-center">
              <FaBuilding className="text-blue-500 mr-2" />
              <p>Finance Dept: finance@ceylonsprint.com</p>
            </div>
            <div className="flex items-center">
              <FaCommentAlt className="text-blue-500 mr-2" />
              <p>Support: support@ceylonsprint.com</p>
            </div>
            <a href={whatsAppLink} target="_blank" rel="noopener noreferrer">
            <div className="flex items-center">
            
              <FaWhatsapp className="text-green-500 mr-2" />
              <p>WhatsApp: +94 70 733 0 432</p>
         
            </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetIn;
