import React, { useState, useEffect } from 'react';
import { FaPhoneAlt } from 'react-icons/fa'; // Importing call icon

const ContactHeader = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      {/* Navbar */}
      {/* <div className={`fixed top-0 left-0 w-full z-30 shadow-md ${isScrolled ? 'bg-[#f0f0f0]' : 'bg-black'}`}>
        <div className="max-w-7xl mx-auto py-2 px-4 md:px-6">
          <Navbar />
        </div>
      </div> */}

      {/* Logo - Only show when not scrolled */}
      {!isScrolled && (
        <div className="absolute top-3 left-3 md:top-5 md:left-6 z-30">
          <img src="../../../asset/light-logo.png" alt="Company Logo" className="h-16 md:h-28" />
        </div>
      )}

      {/* Contact Header Content */}
      <div className="bg-gray-100 py-12 mt-16 md:mt-20"> {/* Adjust margin-top to accommodate navbar */}
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-center items-center text-center">
            <FaPhoneAlt size={60} className="text-blue-500 mb-4 md:mb-0 md:mr-4" /> {/* Large Call Icon */}
            <div>
              <h1 className="text-4xl md:text-5xl font-bold text-gray-800">Let's Talk About Your Project</h1>
              <p className="text-md md:text-lg text-gray-600 mt-2">
                Feel free to ask any questions or let’s talk about our future collaboration.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactHeader;
