import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPosts } from '../../store/actions/postsActions';

const ServicesList = () => {
  const dispatch = useDispatch();
  const services = useSelector(state => state.posts.items);
  const loading = useSelector(state => state.posts.loading);
  const error = useSelector(state => state.posts.error);

  useEffect(() => {
    dispatch(fetchPosts());
  }, [dispatch]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="services-list grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6 p-4 select-none">
      {services.map((service, index) => (
        <div key={index} className="service-item bg-white shadow-lg rounded-lg overflow-hidden hover:shadow-xl transition-shadow duration-300">
          <img 
            src={service.serviceImageUrl} 
            alt={service.serviceTopic} 
            className="w-full h-60 object-cover" 
            onContextMenu={(e) => e.preventDefault()} // Disable right-click on image
          />
          <div className="p-4">
            <h3 className="text-lg font-semibold mb-2">{service.serviceTopic}</h3>
            <ul className="text-sm text-justify space-y-1 transition-transform duration-300 hover:scale-105">
              {service.serviceDescription.map((description, descIndex) => (
                <li key={descIndex}>{description}</li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ServicesList;

