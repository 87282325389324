import React from 'react';
import MainLayout from '../layouts/TermsOfUseLayout';
import TermsOfUse from '../components/termsOfUse/TermsOfUse';

const Home = () => {
 
  return (
    <MainLayout>
        <TermsOfUse />
    </MainLayout>
  );
};

export default Home;
