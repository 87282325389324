import { combineReducers } from 'redux';
import postsReducer from './actions/reducers/postsReducer';
import contactReducer from './actions/reducers/ContactReducer';

// Include other reducers as necessary

const rootReducer = combineReducers({
    posts: postsReducer,
    contact: contactReducer, // Add the contactReducer to your rootReducer
    // Add other reducers here
});

export default rootReducer;