import React from 'react';

const Culture = () => {
  const culturePoints = [
    { title: 'Green Concept Everywhere', detail: 'Committed to sustainability, we embrace eco-friendly practices in all aspects of our operations.' },
    { title: 'Reduced Physical Locations', detail: 'Leveraging technology, we minimize our environmental footprint by limiting physical office spaces.' },
    { title: 'Flexible Working Style', detail: 'Our culture supports work-life balance with flexible schedules and remote work options.' },
    { title: 'Paperless Environment', detail: 'We prioritize digital processes to reduce paper usage, fostering a cleaner, more efficient workplace.' },
    { title: 'Innovative Approach', detail: 'Continuously exploring new methods to enhance efficiency and promote sustainable practices.' },
    { title: 'Employee Well-being', detail: 'Ensuring a healthy work environment focused on mental and physical well-being.' },
    { title: 'Customer Satisfaction', detail: 'Dedicated to delivering excellence and building lasting relationships with our clients.' }
  ];

  return (
    <div className="bg-white py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">Our Company Culture</h2>

        {/* Culture Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {culturePoints.map((point, index) => (
            <div key={index} className="bg-gray-100 p-6 shadow rounded-lg">
              <h3 className="text-xl font-semibold mb-3">{point.title}</h3>
              <p className="text-gray-600">{point.detail}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Culture;
