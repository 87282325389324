import React from 'react';

const services = [
    { title: 'UI & UX Design', description: 'Crafting visually stunning and highly intuitive user experiences.', image: '../../../asset/services/1.png' },
    { title: 'AI Solutions', description: 'Innovative artificial intelligence solutions to drive business growth.', image: '../../../asset/services/2.png' },
    { title: 'Static Web Development', description: 'Robust and reliable static websites for various purposes.', image: '../../../asset/services/3.png' },
    { title: 'Hosting', description: 'High-performance hosting solutions for businesses of all sizes.', image: '../../../asset/services/4.png' },
    { title: 'Dynamic Web Development', description: 'Interactive and dynamic web applications tailored to your needs.', image: '../../../asset/services/5.png' },
    { title: 'ERP Development', description: 'Efficient and integrated ERP systems for streamlined business processes.', image: '../../../asset/services/6.png' },
    { title: 'Mobile App Development', description: 'Cutting-edge mobile applications to enhance user engagement.', image: '../../../asset/services/7.png' },
    { title: 'Cross-Platform Solutions', description: 'Flexible cross-platform development for maximum reach and impact.', image: '../../../asset/services/8.png' },
    { title: 'HR and Payroll Solution', description: 'Comprehensive HR and payroll systems for efficient workforce management.', image: '../../../asset/services/9.png' }
  ];

  const Services = () => {
    return (
      <>
 <style>
        {`
          @keyframes fadeInUp {
            from {
              opacity: 0;
              transform: translateY(20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }

          @keyframes fadeIn {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }

          .animate-fade-in-up {
            animation: fadeInUp 0.6s ease-out;
          }

          .animate-fade-in {
            animation: fadeIn 0.6s ease-out;
          }
        `}
      </style>
      <div className="bg-blue-100 p-4 md:p-8" style={{ userSelect: 'none' }}>
        <h2 className="text-xl font-bold text-center mb-8">Service Spectrum</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
          {services.map((service, index) => (
            <div key={index} className="bg-gray-100 border border-gray-200 rounded-lg shadow-sm p-2 hover:shadow-md transition-shadow duration-300 flex flex-col">
              <img src={service.image} alt={service.title} onContextMenu={(e) => e.preventDefault()} className="w-full h-60 object-cover rounded-t-lg" />
              <div className="p-2">
                <h3 className="font-semibold text-md mb-1 animate-fade-in-up">{service.title}</h3>
                <p className="text-sm animate-fade-in">{service.description}</p>
              </div>
         </div>
          ))}
        </div>
      </div>

      </>
    );
  };
  
  export default Services;
