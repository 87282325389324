import React from 'react';
import MainLayout from '../layouts/SerLayout';
import ServiceItem from '../components/services/ServiceItem';
import WhyUs from '../components/services/WhyUsDtl';
import ContactSellers from '../components/services/ContactSellers';


const Home = () => {
 
  return (
    <MainLayout>
    <ServiceItem />
    <WhyUs />
    <ContactSellers />
    </MainLayout>

  );
};

export default Home;
