import React, { useState, useEffect } from 'react';


const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      {/* Logo - Only show when not scrolled */}
      {!isScrolled && (
        <div className="absolute top-3 left-3 md:top-5 md:left-6 z-30"> 
          <img src="../../../asset/light-logo.png" alt="Company Logo" className="h-16 md:h-28" />
        </div>
      )}

      {/* Header Content for About Us Page */}
      <div className="bg-gray-100 pt-24 md:pt-28 pb-12">
        <div className="container mx-auto px-4 md:px-6 text-center">
          <h1 className="text-3xl md:text-5xl font-bold text-black mb-4">Terms of Use</h1>
          <p className="text-lg md:text-xl text-black font-semibold">
          Welcome to our website. By continuing to browse and use this website, you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern Ceylon Sprint Pvt Ltd's relationship with you in relation to this website.
          </p>
          <hr className="mx-auto w-1/4 my-6 border-2 border-gray-400" /> {/* Modern line style */}
        </div>
      </div>
    </>
  );
};

export default Header;
