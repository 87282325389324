import React from 'react';
import MainLayout from '../layouts/ContactLayout';
import GetIn from '../components/contact/GetIn';
import OurProcess from '../components/contact/ProcessFlow';
import OurNetwork from '../components/contact/OurNetwork';

const Home = () => {
 
  return (
    <MainLayout>
     <GetIn /> 
      <OurProcess />
      <OurNetwork />
    </MainLayout>
  );
};

export default Home;
