import React from 'react';

const WhyUs = () => {
    const features = [
        {
          title: 'Innovative Solutions',
          icon: '🚀',
          description: 'We are at the forefront of innovation, continuously exploring uncharted territories to bring groundbreaking solutions to the table. Our commitment to innovation ensures that we are always ahead of the curve, delivering novel solutions that set new industry standards.'
        },
        {
          title: 'Expert Team',
          icon: '👩‍💼',
          description: 'Our team comprises industry veterans with extensive experience and a proven track record. Each member brings a unique set of skills and insights, allowing us to tackle challenges from various angles and ensure a well-rounded approach to every project.'
        },
        {
          title: 'Customer-Centric Approach',
          icon: '💡',
          description: 'We place our clients at the center of our universe. By understanding their specific needs and aspirations, we tailor our services to provide the most effective and efficient solutions, ensuring client satisfaction and long-term partnerships.'
        },
        {
          title: 'Proven Track Record',
          icon: '🏆',
          description: 'Our history of successful projects and satisfied clients speaks volumes about our capabilities and reliability. We pride ourselves on our track record of delivering high-quality services on time and within budget, earning us a reputation for excellence.'
        },
        {
          title: 'Advanced Technology',
          icon: '🧑‍💻',
          description: 'Staying abreast of the latest technological advancements allows us to offer cutting-edge solutions. Our commitment to technology ensures that we are not just keeping up with the times but also setting new technological benchmarks.'
        },
        {
          title: 'Global Reach, Local Insight',
          icon: '🌍',
          description: 'Our global presence combined with local market knowledge enables us to provide services that are both globally relevant and locally resonant. We understand the nuances of different markets, ensuring our solutions are culturally sensitive and geographically apt.'
        },
        {
          title: 'Reliable Support',
          icon: '🤝',
          description: 'Our relationship with clients goes beyond project completion. We offer ongoing support and maintenance, ensuring that our solutions continue to perform optimally and evolve with your business needs. Our dedicated support team is always ready to assist.'
        },
        {
          title: 'Continuous Improvement',
          icon: '⬆️',
          description: 'We believe in constant evolution and improvement. By keeping abreast of industry trends and gathering feedback, we refine our processes and offerings, ensuring that our services remain relevant, effective, and superior in quality.'
        }
      ];
      

  return (
    <div className="bg-white py-12 px-4">
      <h2 className="text-2xl font-bold text-center mb-6">Why Partner with CeylonSprint</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {features.map((feature, index) => (
          <div key={index} className="flex flex-col items-center text-center p-4 border border-gray-200 rounded-lg shadow-sm hover:shadow-lg transition-shadow duration-300">
            <div className="text-3xl mb-2">{feature.icon}</div>
            <h3 className="font-semibold text-lg mb-1">{feature.title}</h3>
            <p className="text-sm">{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhyUs;
