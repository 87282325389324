import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';

const Navbar = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const handleScroll = () => {
        setIsScrolled(window.scrollY > 50);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

    return (
        <nav className={`fixed top-0 left-0 w-full z-30 transition-colors duration-300 ease-in-out ${isScrolled ? 'bg-white' : 'bg-transparent'}`}>
            <div className="container mx-auto flex justify-between items-center p-4">
                {isScrolled && (
                    <Link to="/" className="shrink-0">
                        <img src="../../../asset/light-logo.png" alt="Logo" className="h-8" />
                    </Link>
                )}

                <div className="md:hidden flex justify-end w-full">
                    <button onClick={toggleMobileMenu} className="focus:outline-none">
                        {isMobileMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
                    </button>
                </div>

                <div className={`md:flex w-full md:items-center md:justify-end ${isMobileMenuOpen ? 'flex flex-col border-b border-gray-300' : 'hidden'} absolute top-full left-0 right-0 bg-white md:bg-transparent md:static`}>
                    <ul className={`flex justify-end flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-6 text-sm font-medium p-4 md:p-0 ${isMobileMenuOpen ? 'md:justify-end justify-end w-full' : ''}`}>
                        <li><Link to="/" className="hover:text-blue-600" onClick={() => setIsMobileMenuOpen(false)}>Home</Link></li>
                        <li><Link to="/Services" className="hover:text-blue-600" onClick={() => setIsMobileMenuOpen(false)}>Services</Link></li>
                        <li><Link to="/About" className="hover:text-blue-600" onClick={() => setIsMobileMenuOpen(false)}>About Us</Link></li>
                        <li><Link to="/Contact" className="hover:text-blue-600" onClick={() => setIsMobileMenuOpen(false)}>Contact Us</Link></li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
