import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    arrows: false,
    // dotsClass: 'slick-dots slick-thumb',
    // customPaging: i => (
    //   <div>{i + 1}</div>
    // ),
  };

  return (
    <>

{/* <style>
        {`
          
          .slick-slider {
            position: relative;
          }

          .slick-dots {
            position: absolute;
            bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            list-style: none;
            padding: 0;
            width: 100%;
          }

          .slick-dots li {
            margin: 0 5px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .slick-dots li button {
            width: 12px;
            height: 12px;
            padding: 0;
            border: none;
            background: rgba(255, 255, 255, 0.8);
            border-radius: 50%;
            cursor: pointer;
            transition: all 0.3s ease;
          }

          .slick-dots li button:hover, .slick-dots li.slick-active button {
            background: rgba(0, 123, 255, 0.9);
          }

          .slick-dots li button:before {
            display: none;
          }
        `}
      </style> */}







      {/* Navbar */}
      {/* <div className={`fixed top-0 left-0 w-full z-30 shadow-md ${isScrolled ? 'bg-[#f0f0f0]' : 'bg-black'}`}>
        <div className="max-w-7xl mx-auto py-2 px-4 md:px-6">
      
        </div>
      </div> */}

      {/* Logo - Only show when not scrolled */}
      {!isScrolled && (
        <div className="absolute top-3 left-3 md:top-5 md:left-6 z-30">
          <img src="../../../asset/light-logo.png" alt="Company Logo" className="h-16 md:h-28" />
        </div>
      )}

      {/* Header Content with Slider  pt-16 md:pt-20 pb-4 p-1 md:p-8*/}
      <header className="relative pt-16">
        <Slider {...settings}>
          {/* Slide 1 */}
          <div className="relative">
            <img src="../../../asset/slid/1.png" alt="Slide 1" className="w-full h-60 md:h-96 object-cover" />
            <div className="absolute top-1/2 left-4 md:left-10 transform -translate-y-1/2 z-20 w-3/4 md:w-1/2 p-4">
              <h2 className="text-md md:text-lg lg:text-xl font-bold text-black">Innovating Your Tomorrow, Today</h2>
              <h1 className="text-lg md:text-2xl lg:text-3xl font-bold text-black">Innovative Software Solutions</h1>
              <p className="text-xs md:text-sm lg:text-md font-bold text-black">Empowering businesses with robust solutions. Transforming ideas into reality.</p>
            </div>
          </div>
          {/* Slide 2 */}
          <div className="relative">
            <img src="../../../asset/slid/2.png" alt="Slide 2" className="w-full h-60 md:h-96 object-cover" />
            <div className="absolute top-1/2 left-4 md:left-10 transform -translate-y-1/2 z-20 w-3/4 md:w-1/2 p-4">
              <h2 className="text-md md:text-lg lg:text-xl font-bold text-white">Innovating Your Tomorrow, Today</h2>
              <h1 className="text-lg md:text-2xl lg:text-3xl font-bold text-black">Global Reach, Ceylon Precision</h1>
              <p className="text-xs md:text-sm lg:text-md font-bold text-black">From the heart of Ceylon to the global stage, our software services are recognized for their precision and excellence.</p>
            </div>

          </div>
          {/* Slide 3 */}
          <div className="relative">
            <img src="../../../asset/slid/3.png" alt="Slide 3" className="w-full h-60 md:h-96 object-cover" />
            <div className="absolute top-1/2 left-4 md:left-10 transform -translate-y-1/2 z-20 w-3/4 md:w-1/2 p-4">
                <h2 className="text-md md:text-lg lg:text-xl font-bold text-white">Innovating Your Tomorrow, Today</h2>
                <h1 className="text-lg md:text-2xl lg:text-3xl font-bold text-black">Future-Ready Platforms & Support</h1>
                <p className="text-xs md:text-sm lg:text-md font-bold text-black">Empowering businesses with future-ready software platforms that drive growth.</p>
              </div>
          </div>
        </Slider>
      </header>
    </>
  );
};

export default Header;