import React from 'react';
import Footer from '../components/common/Footer';
import Header from '../components/termsOfUse/header/Header';
import Navbar from '../components/common/Navbar';

const MainLayout = ({ children, footerClass }) => {
  return (
    <>
    <Navbar />
    <Header />
      <main>{children}</main>
      <Footer className={footerClass} />
    </>
  );
};

export default MainLayout;