import React from 'react';
import { useNavigate } from 'react-router-dom';
const AboutComp = () => {

  const navigate = useNavigate();
  const handleAboutClick = () => {
    navigate('/About'); // Navigate to /About route
  };


  return (
    <div className="flex flex-col md:flex-row w-full h-auto md:h-100 bg-white">
      {/* Left side image box */}
      <div className="w-full md:w-1/4 bg-gray-200">
        {/* Replace with your image */}
        <img src="../../../asset/aboutus/aboutcomp.png" alt="About Us" className="w-full h-60 md:h-full object-cover" />
      </div>

      {/* Right side content */}
      <div className="w-full md:w-3/4 p-4 md:p-8">
        <h2 className="text-2xl md:text-3xl font-bold mb-4">Who We Are?</h2>
        <p className="text-md md:text-lg mb-6 text-justify">
          At CeylonSprint Pvt Ltd, we're more than just a team – we're a powerhouse of tech innovators and connoisseurs, passionately committed to crafting cutting-edge software solutions. Our ambition is to catalyze business success and propel technological evolution. We proudly offer a diverse array of software packages, tailored to meet the dynamic needs of our clients.

          Beyond software development, we operate thriving online platforms for rental, buying, advertising, and e-learning facilities. Our expertise extends into the realm of IT consultation, where we provide a spectrum of services designed to foster growth and innovation. Join us in our journey to reshape the digital landscape, one innovative solution at a time.
        </p>
        <button className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600" onClick={handleAboutClick}>
          About Us
        </button>
      </div>
    </div>
  );
};

export default AboutComp;
