import React from 'react';
import MainLayout from '../layouts/PrivercyLayout';
import PrivacyPolicy from '../components/privercyPolicy/PrivacyPolicy';

const Home = () => {
 
  return (
    <MainLayout>
        <PrivacyPolicy />
    </MainLayout>
  );
};

export default Home;
