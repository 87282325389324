import React from 'react';
import '../../styles/whyUs.css'; // Make sure to create this CSS file

const WhyUs = () => {
    const features = [
      { title: 'Innovative Solutions', icon: '🚀' },
      { title: 'Expert Team', icon: '👩‍💼' },
      { title: 'Customer-Centric Approach', icon: '💡' },
      { title: 'Proven Track Record', icon: '🏆' },
      { title: 'Advanced Technology', icon: '🧑‍💻' },
      { title: 'Global Reach, Local Insight', icon: '🌍' },
      { title: 'Reliable Support', icon: '🤝' },
      { title: 'Continuous Improvement', icon: '⬆️' }
    ];
  
    return (
      <div className="bg-white py-8 ">
        <h2 className="text-center text-2xl font-bold mb-4">Why Partner With Us</h2>
        <div className="feature-carousel overflow-hidden">
          <div className="feature-container">
            {features.map((feature, index) => (
              <div key={index} className="feature-item inline-flex items-center m-2">
                <span className="icon text-3xl mr-2">{feature.icon}</span>
                <span className="title text-xl font-bold whitespace-nowrap">{feature.title}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
  
  export default WhyUs;
