// ContactReducer.js

import {
  SEND_CONTACT_REQUEST,
  CONTACT_REQUEST_SUCCESS,
  CONTACT_REQUEST_FAILURE
} from '../contactActions';

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const contactReducer = (state = initialState, action) => {
  switch (action.type) {
      case SEND_CONTACT_REQUEST:
          return {
              ...state,
              loading: true,
              error: null
          };
      case CONTACT_REQUEST_SUCCESS:
          return {
              ...state,
              loading: false,
              data: action.payload
          };
      case CONTACT_REQUEST_FAILURE:
          return {
              ...state,
              loading: false,
              error: action.payload.error,
              data: null
          };
      default:
          return state;
  }
};

export default contactReducer;
