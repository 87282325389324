import React from 'react';
import MainLayout from '../layouts/AboutLayout';
import WhoWeAre from '../components/about/WhoWeAre';
import Minimal from '../components/about/Minimal';
import BisModel from '../components/about/BisModel';
import Culture from '../components/about/Culture';



const Home = () => {
 
  return (
    <MainLayout>
      <WhoWeAre />
      <Minimal />
      <BisModel />
      <Culture />
    </MainLayout>
  );
};

export default Home;
