import React from 'react';

const BisModel = () => {
  return (
    <div className="bg-blue-300 py-12">
      <div className="container mx-auto px-4 md:flex">

        {/* Hierarchy Structure - Left Side */}
        <div className="md:w-1/2 flex flex-col items-center pr-4">
          {/* CEO - Top Level */}
          <div className="bg-white p-4 rounded shadow-lg mb-4">
            <h3 className="text-xl font-semibold text-gray-800">Chief Executive Officer</h3>
          </div>

          {/* Senior Management - Second Level */}
          <div className="flex flex-col md:flex-row justify-center mb-4 space-y-4 md:space-y-0 md:space-x-2">
            <div className="bg-white p-4 rounded shadow-lg">
              <h3 className="text-lg font-semibold text-gray-800">Chief Technology Officer</h3>
            </div>
            <div className="bg-white p-4 rounded shadow-lg">
              <h3 className="text-lg font-semibold text-gray-800">Chief Finance Officer</h3>
            </div>
            <div className="bg-white p-4 rounded shadow-lg">
              <h3 className="text-lg font-semibold text-gray-800">GM Human Resouces</h3>
            </div>
            <div className="bg-white p-4 rounded shadow-lg">
              <h3 className="text-lg font-semibold text-gray-800">Chief Marketing Officer</h3>
            </div>
            <div className="bg-white p-4 rounded shadow-lg">
              <h3 className="text-lg font-semibold text-gray-800">Chief Reseach And QS</h3>
            </div>
          </div>

          {/* Middle Management - Third Level */}
     
      
          {/* Team Leads - Fourth Level */}
          {/* <div className="bg-white p-4 rounded shadow-lg">
            <h3 className="text-lg font-semibold text-gray-800">Team Lead</h3>
            <p className="text-sm text-gray-600">[Lead Name]</p>
          </div> */}
        </div>

        {/* Departments - Right Side */}
        <div className="md:w-1/2 flex flex-wrap justify-center pl-4">
          <h2 className="w-full text-3xl font-bold text-gray-800 mb-8 text-center">Our Departments</h2>
          {/* Department Boxes */}
          <div className="bg-white p-4 rounded shadow-lg m-2">
            <h3 className="text-lg font-semibold text-gray-800">Engineering</h3>
            <p className="text-sm text-gray-600">Innovative tech solutions</p>
          </div>
          <div className="bg-white p-4 rounded shadow-lg m-2">
            <h3 className="text-lg font-semibold text-gray-800">Marketing</h3>
            <p className="text-sm text-gray-600">Creative strategies</p>
          </div>
          <div className="bg-white p-4 rounded shadow-lg m-2">
            <h3 className="text-lg font-semibold text-gray-800">Human Resouces</h3>
            <p className="text-sm text-gray-600">Empowering our workforce</p>
          </div>

          <div className="bg-white p-4 rounded shadow-lg m-2">
            <h3 className="text-lg font-semibold text-gray-800">Reseach and QS</h3>
            <p className="text-sm text-gray-600">Ensuring excellence</p>
          </div>
          <div className="bg-white p-4 rounded shadow-lg m-2">
            <h3 className="text-lg font-semibold text-gray-800">Finance</h3>
            <p className="text-sm text-gray-600">Financial stewardship</p>
          </div>
          {/* Add more departments as needed */}
        </div>
      </div>
    </div>
  );
};

export default BisModel;
