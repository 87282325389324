import React from 'react';

const Minimal = () => {
  return (
    <div className="bg-gray-100 py-12">
      <div className="container mx-auto px-4">

        {/* Row Container */}
        <div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-4">

          {/* Mission Box */}
          <div className="bg-gray-200 p-6 shadow-lg rounded" style={{ width: '400px', height: 'auto' }}>
            <h3 className="text-2xl font-semibold mb-3">Our Mission</h3>
            <p className="text-gray-600 text-justify">
            Ceylon Sprint is dedicated to empowering individuals, businesses, and groups with the ambition to harness the latest technological advancements. We strive to be the catalysts for technological enhancement, offering innovative services that elevate and transform your digital experiences.
            </p>
          </div>

          {/* Image Box */}
          <div>
            <img src="../../../asset/about/1.png" alt="Visual Representation" style={{ width: '400px', height: 'auto', objectFit: 'cover' }}/>
          </div>

          {/* Vision Box */}
          <div className="bg-white p-6 shadow-lg rounded" style={{ width: '400px', height: 'auto' }}>
            <h3 className="text-2xl font-semibold mb-3">Our Vision</h3>
            <p className="text-gray-600 text-justify">
            We aspire to deliver quality and efficiency through cutting-edge services on a global scale. It's our goal to set new benchmarks in the digital realm, ensuring that every solution we provide is synonymous with excellence and innovation. Ceylon Sprint envisions a world where every client we serve becomes a beacon of digital success.
            </p>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Minimal;
