import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaTiktok,
  FaYoutube,
  FaWhatsapp,
  FaPaintBrush,
  FaCode,
  FaServer,
  FaBrain,
  FaMobileAlt,
  FaCrosshairs,
  FaUserTie,
  FaEnvelope,
  FaInfoCircle,
  FaBriefcase,
} from 'react-icons/fa';

const Footer = () => {
  const [showContactForm, setShowContactForm] = useState(false);

  const toggleContactForm = () => setShowContactForm(!showContactForm);
  const whatsAppNumber = "+94707330432";
  const whatsAppLink = `https://wa.me/${whatsAppNumber}`;

  return (
    <footer className="bg-gray-300 text-gray-800 py-6">
      <div className="container mx-auto px-4 md:flex md:justify-between md:items-center">
        {/* Service List with Icons - Centered and Styled */}
        <div className="mb-4 md:mb-0 w-full md:w-auto">
          <h3 className="font-bold text-xl text-center mb-4 text-blue-600">Our Services</h3>
          <ul className="list-none md:grid md:grid-cols-2 gap-2">
            {[
              { icon: FaPaintBrush, text: 'UI & UX Design' },
              { icon: FaCode, text: 'Static Web Development' },
              { icon: FaCode, text: 'Dynamic Web Development' },
              { icon: FaServer, text: 'Web Hosting' },
              { icon: FaBrain, text: 'AI Solutions' },
              { icon: FaUserTie, text: 'ERP Development' },
              { icon: FaMobileAlt, text: 'Mobile App Development' },
              { icon: FaCrosshairs, text: 'Cross-Platform Solutions' },
              { icon: FaUserTie, text: 'HR and Payroll Solution' },
            ].map((service, index) => (
              <li key={index} className="flex items-center justify-center text-xs md:text-base p-1 hover:bg-gray-200 rounded transition-colors">
                <service.icon className="mr-2 text-blue-500" />
                <span className="text-base md:text-xs lg:text-base">{service.text}</span>
              </li>
            ))}
          </ul>
        </div>

        {/* Quick Navigation with Icons */}
        <div className="mb-4 md:mb-0 text-center">
          <h3 className="font-bold text-lg text-blue-600">Quick Navigation</h3>
          <ul className="list-none">
            <li><Link to="/contact"><FaEnvelope className="inline mr-2" />Contact Us</Link></li>
            <li><Link to="/about"><FaInfoCircle className="inline mr-2" />About Us</Link></li>
            {/* <li><Link to="/careers"><FaBriefcase className="inline mr-2" />Careers</Link></li> */}
          </ul>
        </div>

        {/* Social Media Icons */}
        <div className="flex flex-col items-center">
       {/* Social Media Icons */}
       <div className="flex flex-col items-center">
          <div className="flex mb-2">
            <a href="https://www.facebook.com/CeylonSprint" target="_blank" rel="noopener noreferrer" className="mx-2">
              <FaFacebook size={30} />
            </a>
            <a href="https://twitter.com/CeylonSprint" target="_blank" rel="noopener noreferrer" className="mx-2">
              <FaTwitter size={30} />
            </a>
            <a href="https://www.instagram.com/CeylonSprint" target="_blank" rel="noopener noreferrer" className="mx-2">
              <FaInstagram size={30} />
            </a>
            <a href="https://www.linkedin.com/company/ceylonsprint" target="_blank" rel="noopener noreferrer" className="mx-2">
              <FaLinkedin size={30} />
            </a>
            <a href="https://www.tiktok.com/@CeylonSprint" target="_blank" rel="noopener noreferrer" className="mx-2">
              <FaTiktok size={30} />
            </a>
            <a href="https://www.youtube.com/CeylonSprint" target="_blank" rel="noopener noreferrer" className="mx-2">
              <FaYoutube size={30} />
            </a>
            <a href={whatsAppLink} target="_blank" rel="noopener noreferrer" className="mx-2">
              <FaWhatsapp size={30} />
            </a>
          </div>
          <p className="text-xl font-bold mt-2 text-blue-600">Ceylon Sprint Pvt Ltd</p>
          <img src="../../../asset/light-logo.png" alt="Ceylon Sprint Pvt Ltd Logo" className="h-24" />
        </div>
         
        </div>

        {/* Footer Bottom Text */}
        <div className="text-center mt-4 md:mt-0">
          <p className="font-semibold">&copy; {new Date().getFullYear()} Ceylon Sprint Pvt Ltd. All Rights Reserved.</p>
          <p className="font-medium">
            <Link to="/privacy" className="hover:underline">Privacy Policy</Link> | <Link to="/terms" className="hover:underline">Terms of Use</Link>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
