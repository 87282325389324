import React from 'react';

const WhoWeAre = () => {
  return (
    <div className="bg-blue-300 py-12">
      <div className="container mx-auto px-4 md:grid md:grid-cols-2 items-center">
        {/* Left Side: Description */}
        <div>
          <h2 className="text-2xl md:text-4xl text-center font-bold text-gray-800 mb-6">Who We Are</h2>
          <p className="text-md md:text-lg text-gray-600">
          Ceylon Sprint Pvt Ltd is a collective of tech innovators and experts dedicated to crafting state-of-the-art software solutions that drive business success and technological advancement.
          </p>
        </div>

        {/* Right Side: Tagline with Background */}
        <div className="relative mt-8 md:mt-0 h-full ">
          {/* <div className="absolute inset-0 bg-cover bg-center">
            <div className="absolute inset-0 bg-gray-600 bg-opacity-50"></div>
          </div> */}
          <p className="relative text-xl md:text-4xl font-semibold text-gray-800 text-center flex justify-center items-center">
         CEYLON SPRINT PVT LTD
          </p>
          <p className="relative text-xl md:text-4xl font-semibold text-gray-800 text-center flex justify-center items-center h-full">
          Your trust is the cornerstone of our success
          </p> 

        </div>
      </div>
    </div>
  );
};

export default WhoWeAre;
