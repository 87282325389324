import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { submitContactForm } from '../../store/actions/contactActions';

const ContactSellers = () => {
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      organization: '',
      message: ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      email: Yup.string().email('Invalid email address').required('Email is required'),
      phone: Yup.string().required('Telephone is required'),
      organization: Yup.string(),
      message: Yup.string().required('Description is required')
    }),

    onSubmit: values => {
      dispatch(submitContactForm(values))
      .then(data => {
          // Here data is the response data from your API
          // console.log("Response Data:", data);
          setIsSubmitted(true);
          // Reset form if needed
          formik.resetForm();
          // resetForm();
          setShowForm(false);
      })
      .catch(error => {
          console.error('Submission Error:', error);
      });
  }
  });

  return (
<div className="contact-sellers bg-white p-4 md:p-8">
      <div className="flex justify-end items-center mb-4">
        <h2 className="text-2xl font-bold mr-10">Contact Our Expert Team</h2>
        <button
          onClick={() => setShowForm(!showForm)}
          className={`bg-blue-500 text-white py-3 px-6 rounded hover:bg-blue-600 transition-colors duration-300 text-lg font-semibold ${showForm ? 'bg-red-500 hover:bg-red-600' : ''}`}
        >
          {showForm ? 'Close Form' : 'Contact Us'}
        </button>
      </div>
      {isSubmitted && <div className="text-green-500 mb-4">Thank you for contacting us. We will get back to you soon.</div>}
      {showForm && (
        
        <form onSubmit={formik.handleSubmit} className="max-w-md mx-auto bg-gray-100 p-6 rounded shadow">
          {/* Form fields */}
          {/* Name field */}
         

          <input
            type="text"
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            placeholder="Your Name"
            className="w-full p-2 border border-gray-300 rounded mb-2"
          />
          {/* Error Message */}
          {formik.touched.name && formik.errors.name && <div className="text-red-500 text-xs mb-2">{formik.errors.name}</div>}

          <input
            type="text"
            name="phone"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phone}
            placeholder="Your Telephone"
            className="w-full p-2 border border-gray-300 rounded mb-2"
          />
          {formik.touched.phone && formik.errors.phone && <div className="text-red-500 text-xs mb-2">{formik.errors.phone}</div>}

          <input
            type="email"
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            placeholder="Your Email"
            className="w-full p-2 border border-gray-300 rounded mb-2"
          />
          {formik.touched.email && formik.errors.email && <div className="text-red-500 text-xs mb-2">{formik.errors.email}</div>}

          <input
            type="text"
            name="organization"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.organization}
            placeholder="Your Organization"
            className="w-full p-2 border border-gray-300 rounded mb-2"
          />
          {formik.touched.organization && formik.errors.organization && <div className="text-red-500 text-xs mb-2">{formik.errors.organization}</div>}

          <textarea
            name="message"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.message}
            placeholder="Brief Description of Your Needs"
            className="w-full p-2 border border-gray-300 rounded mb-2"
            rows="4"
          ></textarea>
          
          {/* Submit button */}
          <button type="submit" className="w-full bg-blue-500 text-white py-3 px-4 rounded hover:bg-blue-600 transition-colors duration-300 text-lg font-semibold mt-4">
            Submit
          </button>
        </form>
      )}
    </div>
  );
};

export default ContactSellers;
