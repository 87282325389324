import React, { useState, useEffect } from 'react';
// import Navbar from '../../common/Navbar';
import '../../../styles/SerHeader.css';
// import globeIcon from '../../../../public/asset/services/globe-icon.png';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      {/* Header Content */}
      <div className="pt-16 md:pt-24">
        <header className="bg-gradient-to-r from-blue-300 to-purple-400" style={{ minHeight: '400px' }}>
          <div className="flex flex-col md:flex-row mx-4">
            {/* Left Side: Title and Tagline */}
            <div className="w-full md:w-1/2 flex flex-col justify-center py-4 text-white">
              <h2 className="text-2xl md:text-4xl lg:text-5xl mt-5 font-bold mb-4">Crafting Tomorrow's Technology, Today</h2>
              <p className="text-md md:text-xl italic mb-4">Innovating Your Tomorrow, Today</p>
              <button className="bg-white text-blue-600 px-6 py-2 rounded-full font-semibold hover:bg-gray-200 transition ease-in-out duration-300">Explore Our Services</button>
              <p className="text-justify p-4  text-sm font-bold">Unlock the Potential of Cutting-Edge Technology with Our Versatile Services: At the forefront of innovation, we specialize in delivering a wide array of tech solutions tailored to your unique needs. From crafting dynamic web solutions and developing robust mobile applications to providing holistic HR and payroll systems, our expertise spans across key technological domains. Dive into the world of AI-driven analytics, experience seamless cross-platform functionality, and benefit from our reliable web hosting services. We blend aesthetics with efficiency in our UI/UX designs and offer comprehensive ERP development for streamlined operations. Embrace a future where technology drives growth and efficiency, and let us be your guide on this transformative journey.</p>
            </div>

            {/* Right Side: Dynamic Image */}
            {/* Right Side: Dynamic Image */}
            <div className="w-full md:w-1/2 flex justify-center items-center py-4">
              <img src="../../../asset/services/serhead.png" alt="Dynamic Image" className="w-full max-h-60 md:max-h-96 object-cover" /> {/* Controlled max-height */}
            </div>
          </div>
        </header>
      </div>

      {/* Logo - Only show when not scrolled */}
      {!isScrolled && (
        <div className="absolute top-3 left-3 md:top-5 md:left-6 z-30">
          <img src="../../../asset/light-logo.png" alt="Company Logo" className="h-16 md:h-28" />
        </div>
      )}
    </>
  );
};

export default Header;
